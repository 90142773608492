import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Navbar, Main, About, MainContent, Footer, Search, ContactUs } from './components';
import FeedDetail from './components/contents/FeedDetail';

const App = () => {
  
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
            {/* <>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
            </> */}
          <Route path="/feeds/:service" element={<MainContent />} />
          <Route path="/feed/:_id" element={<FeedDetail />} />
          <Route path="/search" element={<Search />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App;