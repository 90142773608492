import React from 'react';

function JumbotronBanner() {
  return (
    <div className="jumbotron jumbotron-fluid p-5" style={{ backgroundColor: '#f8f9fa', borderRadius: '20px', marginTop: '200px', marginLeft: '60px', marginRight: '60px' }}>
      <div className="container px-0"> {/* Remove padding from container */}
        <div className="row align-items-center">
          {/* Text Column */}
          <div className="col-md-5" style={{ paddingLeft: 0 }}> {/* Remove padding from column */}
            <p className="display-4" style={{ fontSize: '32px', fontWeight: '600', fontFamily: 'Montserrat', margin: 0 }}>
              Find local professionals <br/> for pretty much anything.
            </p>
            <p className="lead" style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px' }}>
              Quickly get quotes from our verified and reviewed professionals.
            </p>
            <a href="https://www.auth.checkaroundme.com" className="btn btn-lg" role="button" style={{ backgroundColor: '#D97B0A', color: 'white', borderColor: '#D97B0A', marginBottom: '20px' }}>
              Get Started →
            </a>
          </div>
          {/* Image Column */}
          <div className="col-md-7 d-md-block">
            <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/Young+couple+sitting+on+sofa+and+using+laptop+at+home.png" style={{ maxWidth: '100%', height: 'auto' }} alt="Couple using laptop" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default JumbotronBanner;