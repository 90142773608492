import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchFeedById } from '../../api';
import { Loading, ErrorAlert } from '../../components';

// Tabbed interface component
const FeedDetail = () => {
    const { _id } = useParams();
    const [feed, setFeed] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('profile');

    useEffect(() => {
        const fetchFeed = async () => {
            try {
                const data = await fetchFeedById(_id);
                setFeed(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchFeed();
    }, [_id]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ErrorAlert />;
    }

    if (!feed) {
        return <div>No feed found.</div>;
    }

    // Handle tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // Tab styles
    const tabStyle = {
        cursor: 'pointer',
        padding: '12px 20px',
        margin: '0 10px',
        borderBottom: '2px solid transparent',
        fontWeight: 'bold',
        display: 'inline-block',
        fontFamily: '"Lato", sans-serif',
        fontSize: '18px',
        color: '#333',
        transition: 'color 0.3s ease, border-bottom 0.3s ease'
    };

    const activeTabStyle = {
        ...tabStyle,
        borderBottom: '2px solid #0073e6', // Slack-like color
        color: '#fff'
    };

    // Text styles
    const usernameStyle = {
        fontFamily: '"Lato", sans-serif',
        fontSize: '28px',
        fontWeight: '700',
        color: '#333',
        marginBottom: '20px'
    };

    const descriptionStyle = {
        fontFamily: '"Lato", sans-serif',
        fontSize: '16px',
        color: '#fff',
        marginBottom: '20px'
    };

    const locationStyle = {
        fontFamily: '"Lato", sans-serif',
        fontSize: '16px',
        color: '#fff',
        marginBottom: '20px',
        fontStyle: 'italic'
    };

    return (
        <div className="container" style={{ fontFamily: '"Lato", sans-serif', marginTop: '170px' }}>
            <h1 style={usernameStyle}>{feed.username}</h1>
            <div style={{ marginBottom: '20px' }}>
                <span
                    style={activeTab === 'profile' ? activeTabStyle : tabStyle}
                    onClick={() => handleTabClick('profile')}
                >
                    Profile
                </span>
                <span
                    style={activeTab === 'images' ? activeTabStyle : tabStyle}
                    onClick={() => handleTabClick('images')}
                >
                    Images
                </span>
            </div>

            {activeTab === 'profile' && (
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <img
                        src={feed.data.imageUrl}
                        alt={feed.data.name}
                        style={{ width: '200px', height: 'auto', borderRadius: '8px', marginRight: '20px' }}
                    />
                    <div style={{ flex: 1 }}>
                        <p style={descriptionStyle}>{feed.data.description}</p>
                        <p style={locationStyle}>Location: {feed.data.city}, {feed.data.state}, {feed.data.country}</p>
                        <p style={locationStyle}>Email: {feed.data.email}</p>
                        <p style={locationStyle}>Phone: {feed.data.phone}</p>
                    </div>
                </div>
            )}

            {activeTab === 'images' && (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: '10px' }}>
                    {feed.images && feed.images.length > 0 ? (
                        feed.images.map((img, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <img src={img} alt={`Image ${index}`} style={{ width: '100%', height: 'auto', borderRadius: '8px', color: 'white' }} />
                            </div>
                        ))
                    ) : (
                        <p style={{ color: 'white'}}>No images available.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default FeedDetail;
