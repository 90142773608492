import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchMerchantFeeds } from '../../api';
import { fetchFeedsRequest, fetchFeedsSuccess, fetchFeedsFailure } from '../../state';
import { Loading, ErrorAlert } from '../../components';

const MainContent = () => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((state) => state.feeds);
    const { service } = useParams();
    const [filteredFeeds, setFilteredFeeds] = useState([]);

    useEffect(() => {
        const fetchFeeds = async () => {
            dispatch(fetchFeedsRequest());
            try {
                const feeds = await fetchMerchantFeeds();
                dispatch(fetchFeedsSuccess(feeds));
            } catch (error) {
                dispatch(fetchFeedsFailure(error.message));
            }
        };

        fetchFeeds();
    }, [dispatch]);

    useEffect(() => {
        if (data && data.data && data.data.length > 0) {
            if (service) {
                const filtered = data.data.filter(feed => feed.service === service);
                setFilteredFeeds(filtered);
            } else {
                setFilteredFeeds(data.data);
            }
        }
    }, [data, service]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ErrorAlert />;
    }

    const VerifiedBadge = () => (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#4CAF50" />
            <path d="M10 14.5L7.5 12L8.91 10.59L10 11.67L14.59 7.08L16 8.5L10 14.5Z" fill="white" />
        </svg>
    );

    return (
        <div>
            <h1 className="display-5" style={{ marginTop: '60px', fontFamily: '"Lato", sans-serif', color: 'white' }}>
                Meet Top Professionals {service && `(${service})`}
            </h1>
            <div className="container">
                {Array.from({ length: Math.ceil(filteredFeeds.length / 2) }).map((_, rowIndex) => (
                    <div className="row justify-content-center mb-4" key={rowIndex}>
                        {filteredFeeds.slice(rowIndex * 2, (rowIndex + 1) * 2).map(feed => (
                            <div className="col-md-6" key={feed._id}>
                                <div className="card" style={{ width: '100%', height: '450px', marginBottom: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div style={{ height: '350px', overflow: 'hidden' }}>
                                        <img src={feed.imageUrl} className="card-img-top" alt={feed.name} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title" style={{ display: "flex", alignItems: "center", textAlign: 'center' }}>
                                            {feed.username} {feed.status === 'Verified' && <VerifiedBadge />}
                                        </h6>
                                        <h6 style={{ fontWeight: 'bold' }}>{feed.service}</h6>
                                        <p style={{ fontWeight: 'bold' }}>{feed.city}, {feed.state}, {feed.country}</p>
                                        <div>
                                            <i className="fas fa-envelope" style={{ color: 'royalblue' }}></i> {feed.email}
                                            <br />
                                            <i className="fas fa-phone" style={{ color: 'royalblue' }}></i> {feed.phone}
                                        </div>
                                        <p>
                                            {feed.description.length > 100 ? (
                                                <>
                                                    {feed.description.substring(0, 100)}...
                                                    <Link to={`/feed/${feed._id}`} style={{ color: 'blue' }}>Read more</Link>
                                                </>
                                            ) : (
                                                feed.description
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <br />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MainContent;
