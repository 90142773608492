// Search.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';

const Search = () => {
  const location = useLocation();
  const searchResults = location.state?.results;
  console.log(searchResults)

  return (
    <div className="container" style={{ marginTop: '190px', fontFamily: '"Lato", sans-serif' }}>
      <h3 className="display-5" style={{ marginTop: '60px', fontFamily: '"Lato", sans-serif', color: 'white', textAlign: 'center' }}>
          Professionals Found
      </h3>
      {searchResults.map((feed, index) => (
        <div className="row justify-content-center mb-4" key={index}>
          <div className="col-md-3">
            <div className="card">
              <div style={{ height: "250px", overflow: "hidden" }}>
                <img src={feed.imageUrl} className="card-img-top" alt={feed.name} style={{ width: "100%", objectFit: "cover" }} />
              </div>
              <div className="card-body">
                <p>
                  <h6 className="card-title" style={{ display: "flex", alignItems: "center"}}>
                    {feed.username} {feed.status === 'Verified' && <span style={{ width: "55px", height: "20px", marginLeft: '15px', display: "inline-block", fontSize: "11px" }} className="badge bg-success sm">Verified</span>}
                  </h6>
                </p>
                <h6 style={{ fontWeight:'bold'}} className="card-text">{feed.service}</h6>
                <p style={{ fontWeight:'bold'}} className="card-text">{feed.city}, {feed.state}, {feed.country}</p>
                <div>
                  <i className="fas fa-envelope" style={{ color: 'royalblue' }}></i> {feed.email}
                  <br />
                  <i className="fas fa-phone" style={{ color: 'royalblue' }}></i> {feed.phone}
                </div>
                <p className="card-text">{feed.description}</p>
              </div>
            </div>
            <br/>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Search;
