import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: {
    loading: false,
    user: {}, // for user object
    token: null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
  },
  feeds: {
    loading: false,
    data: [], // for storing feed data
    error: null,
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // Login reducer
    loginRequest: (state) => {
      state.auth.loading = true;
      state.auth.error = null;
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    loginSuccess: (state, action) => {
      state.auth.loading = false;
      state.auth.success = true;
      state.auth.error = null;
    },
    loginFailure: (state, action) => {
      state.auth.loading = false;
      state.auth.error = action.payload;
      state.auth.success = false;
    },
    // Logout reducer
    logout: (state) => {
      state.auth.user = {};
      state.auth.token = null;
      state.auth.success = false;
    },
    // Feeds reducers
    fetchFeedsRequest: (state) => {
      state.feeds.loading = true;
      state.feeds.error = null;
    },
    fetchFeedsSuccess: (state, action) => {
      state.feeds.loading = false;
      state.feeds.data = action.payload;
    },
    fetchFeedsFailure: (state, action) => {
      state.feeds.loading = false;
      state.feeds.error = action.payload;
    },
  }
});

export const { loginRequest, setLogin, loginSuccess, loginFailure, logout, fetchFeedsRequest, fetchFeedsSuccess, fetchFeedsFailure } = appSlice.actions;

export default appSlice.reducer;
