import axios from "axios"

export const fetchMerchantFeeds = async () => {
    try {
      const response = await fetch('https://www.checkaroundme.com/api/v1/checkaroundme/auth/merchants', {
        headers: {
          Authorization: 'Bearer b8d5faf0448be105d97f84cf12cf95ab413fcef166ac9a3919593d137d08b9cb'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch merchant feeds');
      }
      const data = await response.json();
      console.log(data)
      return data;
    } catch (error) {
        console.log(error)
      console.error('Error fetching merchant feeds:', error.message);
      throw error;
    }
  };
  

  export const fetchFeedById = async (id) => {
    try {
      const response = await fetch(`https://www.checkaroundme.com/api/v1/checkaroundme/auth/merchants/${id}`, {
        headers: {
          Authorization: 'Bearer b8d5faf0448be105d97f84cf12cf95ab413fcef166ac9a3919593d137d08b9cb'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch feed by ID');
      }
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.error('Error fetching feed by ID:', error.message);
      throw error;
    }
  };
  


  export const registerUser = async (formData) => {
    try {
      const response = await axios.post('https://www.checkaroundme.com/api/v1/checkaroundme/auth/register', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const data = response.data;
      console.log('Registration successful:', data);
      return data;
    } catch (error) {
      console.error('Error registering:', error.message);
      throw error;
    }
  };


  export const verifyCode = async (codes, email) => {
    try {
      const response = await axios.post('https://www.checkaroundme.com/api/v1/checkaroundme/auth/verify', {
        verificationCode: codes, // Join the array of codes into a single string
        email: email
      });
      if (!response.data.success) {
        console.log(response.data.Error);
        return response.data.Error;
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error("Error response:", error.response.data);
        console.error("Status code:", error.response.status);
        console.error("Headers:", error.response.headers);
        throw new Error(error.response.data.Error); // Rethrow with custom error message
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
      throw error; // Rethrow the error for further handling
    }
  };


  export const login = async (email, password) => {
    try {
      const response = await axios.post('https://www.checkaroundme.com/api/v1/checkaroundme/auth/login', {
        email,
        password
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred during login.';
    }
  };