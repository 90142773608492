import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  background-color: white;  // Set the background to white
  border-radius: 15px;      // Rounded corners
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);  // Optional: Adds a subtle shadow
  padding: 40px;            // Increase padding for better spacing
  margin-top: 200px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-top: 10px; // Adjusted top margin to reduce the space
  margin-bottom: 10px;
  font-size: 2.5em;
  color: #333;
`;

const Section = styled.section`
  margin-bottom: 10px;
`;

const SubTitle = styled.h2`
  font-size: 1.8em;
  color: #444;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 1.2em;
  color: #666;
`;

const About = () => {
  return (
    <AboutContainer>
      <Title>About Checkaroundme</Title>
      
      <Paragraph>
        Checkaroundme revolutionizes how people find and book services, recognizing that accessible services simplify life. Our platform enables seamless connections between professional service providers and clients, allowing users to effortlessly locate and hire services in their area.
      </Paragraph>
      
      <Paragraph>
        At Checkaroundme, we believe in the power of flexibility and freedom. Our mission is to transform how people connect with professional services by creating a digital platform that facilitates easy and real-time access to affordable, high-quality, and reliable services. We aim to empower both service providers and seekers by simplifying the process of finding and offering services, making the experience seamless and convenient.
      </Paragraph>
      
      <Section>
        <SubTitle>Who We Are</SubTitle>
        <Paragraph>
          Founded in the year 2020, Checkaroundme was built with the vision of transforming the traditional job market. We are a diverse team of innovators, dedicated to creating a platform that bridges the gap between service providers and service seekers.
        </Paragraph>
      </Section>
      
      <Section>
        <SubTitle>What We Do</SubTitle>
        <Paragraph>
          We provide a dynamic marketplace where freelancers and potential clients can find each other easily. Our platform offers a wide range of opportunities across various industries, from creative services and tech support to event staffing and delivery.
        </Paragraph>
      </Section>
      
      <Section>
        <SubTitle>Why Choose Us</SubTitle>
        <Paragraph>
          <strong>User-Friendly Platform:</strong> Our intuitive interface makes it simple to become a freelancer and also post your skills.
        </Paragraph>
        <Paragraph>
          <strong>Quality Assurance:</strong> We vet our freelancers to ensure they meet high standards, giving services seekers peace of mind when hiring through our platform.
        </Paragraph>
        <Paragraph>
          <strong>Supportive Community:</strong> We foster a supportive environment where workers can grow their careers, connect with peers, and access valuable resources.
        </Paragraph>
      </Section>
      
      <Section>
        <SubTitle>Our Values</SubTitle>
        <Paragraph>
          <strong>Flexibility:</strong> We champion the economy because we believe work should fit around life.
        </Paragraph>
        <Paragraph>
          <strong>Trust:</strong> Building a trustworthy platform is paramount. We ensure transparency is observed.
        </Paragraph>
        <Paragraph>
          <strong>Innovation:</strong> Continuously improving and adapting to the changing work landscape keeps us at the forefront of the economy.
        </Paragraph>
      </Section>
      
      <Section>
        <SubTitle>Join Us</SubTitle>
        <Paragraph>
          Whether you're looking to hire, book or become a merchant, Checkaroundme is here to help you succeed. Join our growing community and be part of the future of service.
        </Paragraph>
      </Section>
    </AboutContainer>
  );
}

export default About;
