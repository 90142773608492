import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript

const CarouselComponent = () => {
    const backgroundColor = '#f0f0f0'; // Replace with your desired background color

  return (
    <div className="jumbotron jumbotron-fluid" style={{ backgroundColor: backgroundColor, marginTop: '70px' }}>
        <div className="row">
          <div className="col">
            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active ">
                  <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/mechanic.jpeg" className="d-block w-100" alt="Plumber"/>
                </div>
                <div className="carousel-item">
                  <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/chauffeur.jpeg" className="d-block w-100" alt="Electrician"/>
                </div>
                <div className="carousel-item">
                  <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/plum.jpeg" className="d-block w-100" alt="Cleaners"/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
              </div>
           </div>
          <div className="col">
            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active ">
                  <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/plumber.jpeg" className="d-block w-100" alt="Plumber"/>
                </div>
                <div className="carousel-item">
                  <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/electrician.jpeg" className="d-block w-100" alt="Electrician"/>
                </div>
                <div className="carousel-item">
                  <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/cleaners.jpeg" className="d-block w-100" alt="Cleaners"/>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
    </div>
  );
}


export default CarouselComponent;
