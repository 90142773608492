import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-light text-primary" style={{ height: '200px' }}>
      <div className="container p-4">
        <section className="mb-4">
          {/* Social media icons */}
          <a className="btn btn-outline btn-floating m-1" href="#!" role="button">
            <i className="fab fa-facebook-f"></i>
          </a>
          {/* Twitter */}
          <a className="btn btn-outline btn-floating m-1" href="#!" role="button">
            <i className="fab fa-twitter"></i>
          </a>
          {/* Google */}
          <a className="btn btn-outline btn-floating m-1" href="#!" role="button">
            <i className="fab fa-google"></i>
          </a>
          {/* TikTok */}
          <a className="btn btn-outline btn-floating m-1" href="#!" role="button">
            <i className="fab fa-tiktok"></i>
          </a>
          {/* Instagram */}
          <a className="btn btn-outline btn-floating m-1" href="#!" role="button">
            <i className="fab fa-instagram"></i>
          </a>
        </section>
      </div>
      <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className="text-reset fw-bold" href="https://www.checkaroundme.com/">checkaroundme.com</a>
      </div>
    </footer>
  );
}

export default Footer;
