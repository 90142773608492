import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
`;

const Title = styled.h1`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 2.5em;
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #444;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_gebz7sk', 'template_q5mcakf', formData, 'wrZMi20D5lTocoJkZ')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log('FAILED...', error);
        alert('Failed to send message. Please try again.');
      });

    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <ContactContainer>
      <Title>Contact Us</Title>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="name">Name</Label>
        <Input 
          type="text" 
          id="name" 
          name="name" 
          value={formData.name} 
          onChange={handleChange} 
          required 
        />
        
        <Label htmlFor="email">Email</Label>
        <Input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        
        <Label htmlFor="message">Message</Label>
        <TextArea 
          id="message" 
          name="message" 
          rows="4" 
          value={formData.message} 
          onChange={handleChange} 
          required 
        />
        
        <Button type="submit">Send</Button>
      </Form>
    </ContactContainer>
  );
}

export default ContactUs;
