import React from 'react'
import { MainContent, JumbotronBanner } from '../components';

const Main = () => {
  return (
    <div>
      <JumbotronBanner />
      {/* <CarouselComponent /> */}
      {/* <BelowJumbotron /> */}
      <MainContent />
      
    </div>
  )
}

export default Main
